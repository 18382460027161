import React from "react"
import { NoSideBarLayout } from "../components/layouts"
import SEO from "../components/seo"
import JudgingCalculator from "@hackathons-uk/judging-calculator"

const JudgingCalculatorPage = () => {
  return (
    <NoSideBarLayout>
      <SEO title="Judging Calculator"/>
      <h1>Judging Calculator</h1>
      <JudgingCalculator />
    </NoSideBarLayout>
  )
}

export default JudgingCalculatorPage
